import React, { useState, useEffect, useRef } from "react";
import styles from "./styles/PopUpLogin.module.css";
import stylesLogin from "../Pages/styles/Login.module.css";
import InputForm from "../components/InputForm";
import Button from "../components/Button";
import Alert from "../components/Alert";
import { useNavigate } from "react-router-dom";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import jwtEncode from "jwt-encode";
import {
  BiEnvelope,
  BiInfoCircle,
  BiKey,
  BiLock,
  BiLogoWhatsapp,
  BiUser,
} from "react-icons/bi";
import ReCAPTCHA from "react-google-recaptcha";

const PopUpLoginMod = ({
  setLogin,
  addtionalStyle = {},
  forTrx = false,
  forceFull = false,
  setLoginData = () => {},
}) => {
  // state control
  const [alertDanger, setAlertDanger] = useState({
    state: false,
    content: "",
    variant: "danger",
  });
  const [loading, setLoading] = useState(false);

  // field data basic
  const [inputFocus, setInputFocus] = useState(null);
  const [captcha, setCaptchaState] = useState(null);
  const fieldLogin = {
    email: useRef(null),
    name: useRef(null),
    whatsapp: useRef(null),
  };

  const handleLoginDef = (e) => {
    e.preventDefault();
    if (
      !fieldLogin.email.current ||
      fieldLogin.email.current.value === "" ||
      !fieldLogin.name.current ||
      fieldLogin.name.current.value === ""
    ) {
      setAlertDanger({
        state: true,
        content: "Semua field bertanda * wajib diisi !!!",
        variant: "danger",
      });
      setTimeout(() => {
        setAlertDanger({
          state: false,
          content: "Semua field wajib diisi !!!",
          variant: "danger",
        });
      }, 3000);
    } else if (!captcha) {
      setAlertDanger({
        state: true,
        content: "Re-Captha wajib diisi !!!",
        variant: "danger",
      });
      setTimeout(() => {
        setAlertDanger({
          state: false,
          content: "Semua field wajib diisi !!!",
          variant: "danger",
        });
      }, 3000);
    } else {
      setLoginData({
        email: fieldLogin.email.current.value,
        name: fieldLogin.name.current.value,
        whatsapp: fieldLogin.whatsapp.current.value
          ? fieldLogin.whatsapp.current.value
          : null,
      });
      setLogin(true);
    }
  };

  const handleLoginGoogle = (credentialData) => {
    setLoading(true);
    let data = jwtDecode(credentialData.credential);
    let encodedCredential = jwtEncode(data, process.env.REACT_APP_JWT_SECRET, {
      alg: process.env.REACT_APP_JWT_ALG,
    });
    setLoginData({ email: data.email, credential: encodedCredential });
    setLogin(true);
  };

  const handleLoginGoogleErr = () => {
    setAlertDanger({
      state: true,
      content: "Terjadi kesalahan. Silahkan coba lagi !",
      variant: "danger",
    });
    setTimeout(() => {
      setAlertDanger({
        state: false,
        content: "Terjadi kesalahan. Silahkan coba lagi !",
        variant: "danger",
      });
    }, 3000);
  };

  useEffect(() => {
    window.addEventListener("click", (e) => {
      if (e.target === fieldLogin.email.current) {
        setInputFocus("email");
      } else if (e.target === fieldLogin.name.current) {
        setInputFocus("username");
      } else if (e.target === fieldLogin.whatsapp.current) {
        setInputFocus("whatsapp");
      } else {
        setInputFocus(null);
        // console.log(e.target);
      }
    });
  });

  return (
    <div
      className={`${forceFull ? styles.WrapperForceFull : ""} ${
        styles.Wrapper
      }`}
      style={{ ...addtionalStyle }}
    >
      <div
        className={`${forceFull ? styles.ModalDialogForceFull : ""} ${
          styles.ModalDialog
        }`}
      >
        <div
          className={`${forceFull ? styles.PopUpBoxForceFull : ""} ${
            styles.PopUpBox
          }`}
        >
          <div className={stylesLogin.Content} style={{ marginTop: 0 }}>
            <div>
              <form onSubmit={handleLoginDef}>
                <div className={styles.AlertBox}>
                  {!forTrx ? (
                    <Alert
                      type={"warning"}
                      isShow={true}
                      setShowFn={() => {}}
                      message={
                        "Belum login atau sesi login telah habis. Login untuk melanjutkan"
                      }
                      closeBtn={false}
                    />
                  ) : (
                    <></>
                  )}
                </div>
                <div className={styles.AlertBox}>
                  <Alert
                    type={alertDanger.variant}
                    isShow={alertDanger.state}
                    setShowFn={() => {}}
                    message={alertDanger.content}
                    closeBtn={false}
                  />
                </div>
                <div
                  className={`${stylesLogin.SubTitle} ${stylesLogin.TextCenter}`}
                  style={{ marginTop: "5px" }}
                >
                  Lanjutkan dengan akun Google
                </div>
                <div
                  className={styles.FormFieldBox}
                  style={forTrx ? { marginBottom: "25px" } : {}}
                >
                  <div className={stylesLogin.GoogleLoginBtn}>
                    <GoogleOAuthProvider
                      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                    >
                      <GoogleLogin
                        theme="filled_black"
                        logo_alignment="center"
                        shape="pill"
                        onSuccess={handleLoginGoogle}
                        onError={handleLoginGoogleErr}
                      ></GoogleLogin>
                    </GoogleOAuthProvider>
                  </div>
                </div>
                <div
                  className={`${stylesLogin.SubTitle} ${stylesLogin.TextCenter}`}
                >
                  Atau Isi Data Diri Anda Berikut
                </div>
                <div>
                  <div className={styles.InfoBox} style={{ marginTop: "20px" }}>
                    <BiInfoCircle />
                    <div className={styles.InfoText}>
                      Anda bisa melnajutkan dengan sekali klik dengan akun
                      google diatas. Namun jika tidak mempunyai akun google,
                      anda juga bisa melanjutkan dengan mengisi data diri anda
                      berikut.
                    </div>
                  </div>
                  <div
                    className={`${stylesLogin.BoxInput} ${
                      inputFocus === "email" || inputFocus === "username"
                        ? stylesLogin.ShadowBoxInput2
                        : inputFocus === "whatsapp"
                        ? stylesLogin.ShadowBoxInput1
                        : ""
                    }`}
                  >
                    <div className={stylesLogin.FormFieldInput}>
                      <label
                        className={stylesLogin.TitleInput}
                        style={{
                          color:
                            inputFocus === "email"
                              ? "rgb(202, 12, 100)"
                              : "#000",
                        }}
                        htmlFor="email-input"
                        onFocus={() => {
                          setInputFocus("email");
                        }}
                      >
                        <BiEnvelope />
                        <div style={{ flexDirection: "row", gap: "3px" }}>
                          Email <span style={{ color: "red" }}>*</span>
                        </div>
                      </label>
                      <InputForm
                        id={"email-input"}
                        className={stylesLogin.FieldInput}
                        refData={fieldLogin.email}
                        type={"email"}
                        required
                        placeholder={"Tuliskan alamat email akunmu"}
                      />
                    </div>
                    <div className={stylesLogin.FormFieldInput}>
                      <label
                        className={stylesLogin.TitleInput}
                        style={{
                          color:
                            inputFocus === "username"
                              ? "rgb(202, 12, 100)"
                              : "#000",
                        }}
                        htmlFor="username-input"
                        onFocus={() => {
                          setInputFocus("username");
                        }}
                      >
                        <BiUser />
                        <div style={{ flexDirection: "row", gap: "3px" }}>
                          Nama <span style={{ color: "red" }}>*</span>
                        </div>
                      </label>
                      <InputForm
                        id={"username-input"}
                        className={stylesLogin.FieldInput}
                        refData={fieldLogin.name}
                        type={"text"}
                        required
                        placeholder={"Tuliskan username akunmu"}
                      />
                    </div>
                    <div className={stylesLogin.FormFieldInput}>
                      <label
                        className={stylesLogin.TitleInput}
                        style={{
                          color:
                            inputFocus === "whatsapp"
                              ? "rgb(202, 12, 100)"
                              : "#000",
                        }}
                        htmlFor="whatsapp-input"
                        onFocus={() => {
                          setInputFocus("whatsapp");
                        }}
                      >
                        <BiLogoWhatsapp />
                        <div style={{ flexDirection: "row", gap: "3px" }}>
                          WhatsApp
                          <span style={{ color: "red" }}>*</span>
                        </div>
                      </label>
                      <InputForm
                        id={"whatsapp-input"}
                        className={stylesLogin.FieldInput}
                        refData={fieldLogin.whatsapp}
                        type={"numeric"}
                        placeholder={"Tuliskan username akunmu"}
                        required
                      />
                    </div>
                  </div>

                  <div className={stylesLogin.CapcthaField}>
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                      onChange={(value) => {
                        setCaptchaState(value);
                      }}
                      style={{ margin: "auto" }}
                    />
                  </div>

                  <div className={styles.FormFieldBox}>
                    {loading ? (
                      <Button
                        bgColor={"rgb(212 132 169)"}
                        borderColor={"rgb(212 132 169)"}
                        title={"Loading ..."}
                        icon={
                          <div
                            className="spinner-border"
                            style={{ width: "20px", height: "20px" }}
                            animation="border"
                          />
                        }
                        center={true}
                        style={{ width: "100%", textAlign: "center" }}
                      />
                    ) : (
                      <Button
                        title={"Lanjutkan"}
                        typeBtn="submit"
                        classes={[styles.FormButton]}
                        style={{ width: "100%", margin: "auto" }}
                      />
                    )}
                    <div
                      style={{
                        flexDirection: "row",
                        margin: "auto",
                        gap: "10px",
                      }}
                    ></div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopUpLoginMod;
