import React, { useEffect, useState } from "react";
import styles from "./styles/InputForm.module.css";
import Button from "./Button";
import { BiShow } from "react-icons/bi";

const filterArrCharToNumber = (arrChar = []) => {
  let newArr = arrChar.filter(
    (value) =>
      value == 0 ||
      value == 1 ||
      value == 2 ||
      value == 3 ||
      value == 4 ||
      value == 5 ||
      value == 6 ||
      value == 7 ||
      value == 8 ||
      value == 9
  );
  return newArr.join("");
};

const formatCurrency = (e) => {
  const numberFormat = Intl.NumberFormat("id-ID");
  let inputVal =
    e.target.value.split("Rp.").length > 1
      ? e.target.value.split("Rp.")[1]
      : e.target.value.split("Rp.")[0];
  inputVal = inputVal.split("");
  let res = "Rp." + numberFormat.format(filterArrCharToNumber(inputVal));
  e.target.value = res;
};

const formatNumeric = (e) => {
  let inputVal = e.target.value.split("");
  let res = filterArrCharToNumber(inputVal);
  e.target.value = res;
};

const InputForm = ({
  id,
  type,
  placeholder,
  style = {},
  className = [],
  refData,
  required = false,
  readOnly = false,
  hidePassBtn = true,
  min = 0,
  autoFocus = false,
  onFocus = () => {},
  value,
  onInput = () => {},
  onChange = () => {},
}) => {
  const [showPass, setShowPass] = useState(false);

  const classNames = [
    styles.InputForm,
    type === "password" ? styles.InputPassword : "",
  ].concat(className);

  const showPassFn = () => {
    setShowPass(!showPass);
  };
  return (
    <>
      <input
        autoFocus={autoFocus}
        id={id}
        type={showPass ? "text" : type}
        placeholder={placeholder}
        className={classNames.join(" ")}
        style={style}
        ref={refData}
        required={required}
        readOnly={readOnly}
        onFocus={onFocus}
        min={min}
        onInput={
          type === "currency"
            ? (e) => {
                formatCurrency(e);
                onInput(e);
              }
            : type === "numeric"
            ? (e) => {
                formatNumeric(e);
                onInput(e);
              }
            : onInput
        }
        onChange={onChange}
        defaultValue={value}
      />
      {hidePassBtn ? (
        <Button
          style={{ display: type === "password" ? "unset" : "none" }}
          classes={styles.HidePassword}
          bgColor={"white"}
          borderColor={"white"}
          textColor={"black"}
          icon={<BiShow />}
          fnOnClick={showPassFn}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default InputForm;
