import React from "react";

export default function Compass() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        fill="color(display-p3 0.1041 0.1041 0.1041)"
        fillRule="evenodd"
        d="M9 2.063a6.938 6.938 0 100 13.875A6.938 6.938 0 009 2.062zM.937 9a8.062 8.062 0 1116.125 0A8.062 8.062 0 01.937 9zm9.45-2.114c-.454.12-1.065.364-1.946.716-.406.162-.482.2-.538.25a.568.568 0 00-.052.051c-.048.056-.087.132-.249.538-.352.88-.596 1.492-.716 1.946-.125.472-.064.593-.044.622.04.059.09.11.149.149.029.02.15.081.622-.044.454-.12 1.065-.364 1.946-.716.406-.163.482-.2.537-.25a.555.555 0 00.052-.052c.05-.055.087-.13.25-.537.352-.88.596-1.492.716-1.946.125-.472.064-.593.044-.622a.563.563 0 00-.15-.15c-.028-.019-.149-.08-.621.045zm-.289-1.088c.518-.137 1.077-.203 1.543.113.176.12.328.272.447.448.317.465.25 1.025.113 1.542-.14.529-.41 1.204-.744 2.038l-.015.038-.025.063c-.122.307-.23.579-.423.798-.048.055-.1.108-.156.156-.22.193-.491.3-.798.423l-.063.025-.038.015c-.834.334-1.51.604-2.038.744-.517.137-1.077.204-1.542-.113a1.688 1.688 0 01-.448-.447c-.316-.466-.25-1.025-.112-1.543.14-.528.41-1.203.743-2.037l.015-.038.026-.063c.122-.307.23-.579.423-.798.048-.056.1-.108.156-.156.22-.193.49-.301.798-.423l.063-.026.038-.015c.834-.333 1.51-.603 2.037-.744z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}
